<template>
  <div
    id="welcome-page"
    class="d-flex flex-column justify-content-end h-100 page py-3 px-2"
  >
    <div class="position-absolute">
      <img src="../assets/img/mdj-full-gray.svg" height="200" />
    </div>
    <h2 class="title mb-3">Découvrez votre application icioncuisine.com !</h2>
    <b-media>
      <font-awesome-icon slot="aside" icon="utensils" fixed-width size="2x" />
      <p>
        Recherchez autour de vous un restaurant, un Bon Plan ou un Menu Du Jour
        !
      </p>
    </b-media>
    <b-media>
      <font-awesome-icon
        slot="aside"
        icon="map-marker-alt"
        fixed-width
        size="2x"
      />
      <p>
        Repérez-les d'un simple coup d'oeil et promenez vous sur le plan pour en
        trouver d'autres !
      </p>
    </b-media>
    <b-media>
      <font-awesome-icon slot="aside" icon="heart" fixed-width size="2x" />
      <p>
        Ajoutez vos restaurants préférés en favoris et recevez chaque jour leurs
        Bons Plans et leurs menus
      </p>
    </b-media>
    <b-media>
      <font-awesome-icon slot="aside" icon="star" fixed-width size="2x" />
      <p>Mémoriez les restaurants que vous aimeriez tester</p>
    </b-media>
    <btn-with-icon
      :icon="['fas', 'chevron-right']"
      variant="primary"
      block
      :only-icon="false"
      :to="{ name: 'list' }"
    >
      Continuer
    </btn-with-icon>
  </div>
</template>
<script>
export default {
  computed: {
    target: function () {
      if (this.authenticated) {
        return { name: "list" }
      }
      return { name: "public.login" }
    }
  }
}
</script>
